import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
// import './index.css';
import '../node_modules/picnic/picnic.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useLocation,
} from 'react-router-dom';
import queryString from 'query-string';


const Page = () => {
    const [formState, setFormState] = React.useState({
        success: null,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);

        fetch('/rsvp.php', {
            method: 'POST',
            body: data,
        }).then(
            (response) => {
                if (!response.ok) {
                    throw new Error('Could not complete request');
                }
                setFormState({
                    success: true,
                })
            }
        ).catch(err => {
            setFormState({
                success: false,
            })
        })

        return false;
    }

    const params = useParams();
    const location = useLocation();
    const parsed = queryString.parse(location.search);

    const audience = params.audience || parsed.audience;

    const isAudienceFriends = audience === 'friends';

    const containerClass = classNames('container', {
        'audience-friends': isAudienceFriends,
    })

    const image = isAudienceFriends ?
        'img/arches_photo.jpg': 'img/img_spiral.jpg';

    return (
        <div className="app">
            <div className={containerClass}>
                <div className="envelope open">
                    <div className="flap front"></div>
                    <div className="flap top"></div>
                    {<Card image={image} />}
                </div>
                <div class="headline">
                    Save the Date
                </div>
                <div class="invitation-to-follow">invitation to follow</div>
                {formState.success !== true && <RSVP onSubmit={handleSubmit} />}
                {formState.success === true && <Thanks />}
                {formState.success === false &&
                    <div style={{
                        color: '#ff4136',
                    }}>
                        We had an error recording your RSVP. If you think this is a mistake, let Vaibhav know.
                    </div>}
            </div>
        </div>
    );
};


const RSVP = ({onSubmit}) => {
    const [name, setName] = React.useState('');
    const [spouse, setSpouse] = React.useState('');
    const [kids, setKids] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [zip, setZip] = React.useState('');

    const location = useLocation();
    const parsed = queryString.parse(location.search);
    const haveContactInfo = parsed.s === '1';

    const params = useParams();
    const audience = params.audience || parsed.audience;

    const isAudienceFriends = audience === 'friends';

    const canSubmit = Boolean(
        haveContactInfo
            ? name
        : name && email && address1 && zip
    );

    return (
        <form className="rsvp fadeInBottom" onSubmit={e => onSubmit(e)}>
            <h1 className="rsvp-title"> Please enter your contact information for the invitation </h1>
            <fieldset>
                <label>
                    Your Full Name&#42;
                    <input type="text" name="name" onChange={e => setName(e.target.value)} value={name} />
                </label>
                {!haveContactInfo &&
                    <React.Fragment>
                        <label>
                            {isAudienceFriends
                                ? <span>Your Partner's Name</span>
                                : <span>Your Spouse's Name</span>}
                            <input type="text" name="spouse" onChange={e => setSpouse(e.target.value)} value={spouse} />
                        </label>
                        {!isAudienceFriends &&
                            <label>
                                Your Kids' names
                                <input type="text" name="kids" onChange={e => setKids(e.target.value)} value={kids} />
                            </label>}
                        <label>
                            Your Email&#42;
                            <input type="text" name="email" onChange={e => setEmail(e.target.value)} value={email} />
                        </label>
                        <label>
                            Mailing Address&#42;
                            <input type="text" name="address1" placeholder="Address Line 1" onChange={e => setAddress1(e.target.value)} value={address1} />
                        </label>
                        <input type="text" name="address2" placeholder="Address Line 2" onChange={e => setAddress2(e.target.value)} value={address2} />
                        <input type="text" name="zip" placeholder="Zip Code" onChange={e => setZip(e.target.value)} value={zip} />
                    </React.Fragment>
                }
            </fieldset>
            <button class="submit-rsvp" type="submit" disabled={!canSubmit}> Submit </button>
        </form>
    );
}

const Thanks = () => (
    <div>
        <p> Thanks for your response. We'll be in touch soon with more details.</p>

        <p className="text-right"> Vaibhav &amp; Divya </p>

        Add to Calendar<br />
        <a target="_blank" rel="noopener noreferrer" className="button" href="https://calendar.google.com/calendar/r/eventedit?text=Vaibhav+and+Divya+Wedding&dates=20200523/20200524&location=New+Jersey,+USA">
            Google
        </a>
        <a className="button" href="/vaibhav_divya_save_date.ics">
            Apple / iCal
        </a>

    </div>
);

const Card = ({image}) => (
    <div className="card letter">
        <img src={image} alt="Vaibhav and Divya looking at each other" />

        <div className="details">
            <div className="subline">
                Vaibhav &amp; Divya | 05.23.2020
            </div>

            <div className="subline">
                New Jersey, USA
            </div>
        </div>
    </div>
);

const Postponed = () => (
    <div style={{ padding: '0 8px' }}>
        <p>Hey folks,</p>

        <p>We've all seen the news, and have had the coronavirus disrupt our lives. Divya and I are postponing our wedding celebrations because it doesn't seem like a gathering of our friends and family will be possible in two months.</p>

        <p>We ask that you cancel any plans that you made to attend our wedding (originally May 23rd).</p>

        <p>We'll be setting a new date once life has returned to normal, and you'll be the first to know.</p>

        Thanks,<br />
        Vaibhav &amp; Divya
    </div>
);

const Root = () => (
    <Router>
        <Switch>
          <Route path="/:audience">
            <Page />
          </Route>
          <Route path="/">
            <Page />
          </Route>
        </Switch>
    </Router>
);

const root = document.querySelector('#root');
ReactDOM.render(<Postponed />, root);
